import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import Typography from "@mui/material/Typography";
import { Box, Icon, IconButton, ListItemIcon, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import UnpublishedTwoToneIcon from "@mui/icons-material/UnpublishedTwoTone";
import TerminalIcon from "@mui/icons-material/Terminal";
import BuildIcon from "@mui/icons-material/Build";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import { api_set_special_attendance } from "../api";
import moment from "moment";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import WebIcon from "@mui/icons-material/Web";
import HealingIcon from "@mui/icons-material/Healing";

function icon_from_attendance_type(attendance_type, message) {
  console.log(attendance_type);

  function get_icon() {
    switch (attendance_type) {
      case "Y":
        return <CheckCircleIcon color="success" />;
      case "N":
        return <CancelIcon color="error" />;
      case "V":
        return <FlightIcon color="info" />;
      case "CNCL":
        return <UnpublishedTwoToneIcon color="error" />;
      case "ACNL":
        return <UnpublishedTwoToneIcon color="error" />;
      case "LTAT":
        return <CheckCircleTwoToneIcon color="success" />;
      case "LTMS":
        return <CancelTwoToneIcon color="error" />;
      case "MISS":
        return <CancelTwoToneIcon color="error" />;
      case "UNXD":
        return <CheckCircleTwoToneIcon color="success" />;
      case "MED":
        return <HealingIcon color="error" />;
      case "MED?":
        return <HealingIcon color="warning" />;
      case "MEDY":
        return <HealingIcon color="success" />;
      case "UNXM":
        return <HealingIcon color="success" />;
      case "MISM":
        return <HealingIcon color="error" />;
      default:
        return <ErrorOutlineIcon color="warning" />;
    }
  }

  if (message) {
    console.log("hello", message);
    return (
      <Tooltip title={message} arrow>
        {get_icon()}
      </Tooltip>
    );
  }
  return get_icon();
}

function icon_from_badge(badge) {
  switch (badge) {
    case "HardwareLead":
      return (
        <Tooltip title="Hardware Lead" arrow>
          <BuildIcon fontSize="small" />
        </Tooltip>
      );
    case "SoftwareLead":
      return (
        <Tooltip title="Software Lead" arrow>
          <TerminalIcon />
        </Tooltip>
      );
    case "Captain":
      return (
        <Tooltip title="Team Captain" arrow>
          <StarIcon />
        </Tooltip>
      );
    case "Lieutenant":
      return (
        <Tooltip title="Team Lieutenant" arrow>
          <StarHalfIcon />
        </Tooltip>
      );
    case "RobotRunner":
      return (
        <Tooltip title="Robot Runner" arrow>
          <DirectionsRunIcon />
        </Tooltip>
      );
    case "OnsitePresenter":
      return (
        <Tooltip title="Onsite Presenter" arrow>
          <CoPresentIcon />
        </Tooltip>
      );
    case "Blogger":
      return (
        <Tooltip title="Team Blogger" arrow>
          <WebIcon />
        </Tooltip>
      );
    case "DocumentationLead":
      return (
        <Tooltip title="Documenation Lead" arrow>
          <TaskOutlinedIcon />
        </Tooltip>
      );
    default:
      return null;
  }
}

function PersonListItem(props) {
  const [attendanceType, setAttendanceType] = useState(
    props.person.attendance_type
  );

  const attendance_message = props.person.attendance_message;

  const isInManageWindow = moment().isBetween(
    moment(`${props.date} ${props.start}`, "YYYY-MM-DD HH:mm:ss").subtract(
      0.25,
      "hour"
    ),
    moment(`${props.date} ${props.end}`, "YYYY-MM-DD HH:mm:ss").add(
      0.25,
      "hour"
    )
  );

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          {icon_from_attendance_type(attendanceType, attendance_message)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={props.person.username}
        // secondary="Attending"
      />
      <ListItemIcon sx={{ pl: 1 }}>
        <Icon>{icon_from_badge(props.person.badge)}</Icon>
      </ListItemIcon>
      {(() => {
        const set_normal = () => {
          api_set_special_attendance(
            "NONE",
            props.date,
            props.person.username
          ).then((res) => {
            setAttendanceType(res.attendance_type);
          });
        };
        const set_missing = () => {
          api_set_special_attendance(
            "MISS",
            props.date,
            props.person.username
          ).then((res) => {
            setAttendanceType(res.attendance_type);
          });
        };
        const set_attending = () => {
          api_set_special_attendance(
            "ATTEND",
            props.date,
            props.person.username
          ).then((res) => {
            setAttendanceType(res.attendance_type);
          });
        };
        if (props.permissions?.can_configure && isInManageWindow) {
          return (
            <ListItemIcon>
              <IconButton onClick={set_attending}>
                <CheckCircleTwoToneIcon color="success" fontSize="small" />
              </IconButton>
              <IconButton onClick={set_missing}>
                <CancelTwoToneIcon color="error" fontSize="small" />
              </IconButton>
              <IconButton onClick={set_normal}>
                <CircleTwoToneIcon fontSize="small" />
              </IconButton>
            </ListItemIcon>
          );
        }
      })()}
    </ListItem>
  );
}

export default function ListOfDayAttendance(props) {
  const create_list_items = () => {
    if (props.attendance === undefined) {
      return <></>;
    }

    let items = [];
    props.attendance.forEach((person) => {
      items.push(
        <PersonListItem
          person={person}
          date={props.date}
          start={props.start}
          end={props.end}
          permissions={props.permissions}
          key={person.username}
        />
      );
    });
    return items;
  };

  return (
    <React.Fragment>
      <Typography variant="h4" align="center">
        {props.title}
      </Typography>
      <Box display="flex" justifyContent={props.align} alignItems={props.align}>
        <List dense={true}>{create_list_items()}</List>
      </Box>
    </React.Fragment>
  );
}
