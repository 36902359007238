import { useNavigate } from "react-router-dom";
import * as React from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  api_create_vacation,
  api_delete,
  api_get_user_url,
  api_get_vacations,
  api_me,
  api_modify,
} from "../api";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Checkbox from "@mui/material/Checkbox";

function VacationCard(props) {
  const [gone, setGone] = useState(moment(props.start) ?? moment());
  const [back, setBack] = useState(moment(props.end) ?? moment());
  const { url, setCards, setForceUpdate } = props;

  // useEffect(() => {
  //
  // }, [gone, back, setForceUpdate]);

  return (
    <Card
      sx={{
        margin: 3,
        backgroundColor: "lightgray",
        maxWidth: 600,
      }}
    >
      <CardContent align="center">
        <br />
        <DatePicker
          label="Vacation Start"
          value={gone}
          onChange={(new_value) => {
            api_modify(url, {
              gone_as_of: new_value.format("YYYY-MM-DD"),
            })
              .then((r) => {
                setGone(new_value);
              })
              .then(() => {
                setForceUpdate((prev) => {
                  return !prev;
                });
              });
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="Vacation End"
          value={back}
          minDate={gone}
          onChange={(new_value) => {
            api_modify(url, {
              back_as_of: new_value.format("YYYY-MM-DD"),
            })
              .then((r) => {
                setBack(new_value);
              })
              .then(() => {
                setForceUpdate((prev) => {
                  return !prev;
                });
              });
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </CardContent>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CardActions>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              api_delete(url).then(() => {
                setCards((prev) => {
                  const cpy = { ...prev };
                  delete cpy[url];
                  return cpy;
                });
              });
            }}
          >
            Delete
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
}

export default function () {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState(undefined);
  const [cards, setCards] = useState({});
  const [update, setUpdate] = useState(false); // TODO: do something less hacky
  const [noVacation, setNoVacation] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      api_me()
        .then((result) => {
          setUser(result);
          setIsAuth(true);
        })
        .catch((e) => {
          console.log("error", e);
          localStorage.clear();
          navigate("/sign_in", { state: { redirect: "/vacations" } });
        });
    } else {
      navigate("/sign_in", { state: { redirect: "/vacations" } });
    }
  }, [navigate]);

  function getVacationCard(card) {
    return (
      <VacationCard
        key={card.vacation_id}
        start={card.gone_as_of}
        end={card.back_as_of}
        url={card.url}
        setCards={setCards}
        setUpdate={setUpdate}
        no_vacations={card.no_vacations}
        setForceUpdate={setForceUpdate}
      />
    );
  }

  function create_vacation(no_vacations) {
    api_get_user_url(user.username).then((user_url) => {
      let now = moment();
      api_create_vacation(
        user_url,
        now.format("YYYY-MM-DD"),
        now.format("YYYY-MM-DD"),
        no_vacations
      ).then((result) => {
        setCards((prev) => ({
          ...prev,
          [result.url]: getVacationCard(result),
        }));
      });
    });
  }

  useLayoutEffect(() => {
    if (!user?.username) {
      return;
    }
    api_get_vacations(user?.username).then((r) => {
      let card_list = {};
      r.forEach((card) => {
        card_list[card.url] = getVacationCard(card);
      });
      setCards(card_list);
      setNoVacation(r[0]?.no_vacations === true);
    });
  }, [user, forceUpdate]);

  const Display = () => {
    let values = Object.values(cards);
    if (
      (values.length === 0 && !noVacation) ||
      (values.length === 1 && noVacation)
    ) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={(e) => {
                    let checked = e.target.checked;
                    if (checked) {
                      create_vacation(true);
                    } else {
                      let url = values[0]?.props.url;
                      api_delete(url).then(() => {
                        setCards((prev) => {
                          const cpy = { ...prev };
                          delete cpy[url];
                          return cpy;
                        });
                      });
                    }
                    setNoVacation(checked);
                  }}
                />
              }
              label="No Vacations"
              checked={noVacation}
            />
          </FormGroup>
          <br />
          <br />
        </Box>
      );
    }
    return values;
  };

  const DisplayButton = () => {
    let values = Object.values(cards);
    if (values[0]?.props.no_vacations) {
      return <></>;
    }
    return (
      <Button
        variant="outlined"
        startIcon={<AddCircleIcon />}
        onClick={() => {
          create_vacation();
        }}
      >
        Add
      </Button>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <br />
      <Typography variant="h2" align="center">
        Vacations
      </Typography>
      <Typography variant="h6" align="center">
        Add your vacations below so we can plan out the season! Check the box
        for "No Vacations" if you do not have any vacations that will interfere
        with the season, otherwise use the "add" button to add those vacations.
      </Typography>
      <br />
      <div align="center">
        <Display />
        <DisplayButton />
      </div>
      <br />
      <br />
    </LocalizationProvider>
  );
}
