// https://mui.com/material-ui/react-table/
import * as React from "react";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { api_get_attendance } from "../api";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AttendanceRow from "../components/AttendanceRow";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import FlightIcon from "@mui/icons-material/Flight";
import Button from "@mui/material/Button";
import GroupsIcon from "@mui/icons-material/Groups";

const columns = [
  { id: "day", label: "Day of Week", minWidth: null },
  { id: "time", label: "Time", minWidth: null },
  { id: "attending", label: "Attending", minWidth: null },
  // {id: 'start', label: 'Start Time', minWidth: null},
  // {id: 'end', label: 'End Time', minWidth: null},
  { id: "message", label: "Title", minWidth: null },
];

export default function ScheduleConfiguration() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [days, setDays] = React.useState([]);

  const [isAuth, onPressToday, permissions] = useOutletContext();

  useEffect(() => {
    api_get_attendance().then((res) => {
      setDays(res);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    api_get_attendance().then((res) => {
      setDays(res);
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {permissions?.see_vacations ? (
          <Button
            variant="outlined"
            startIcon={<FlightIcon />}
            component={RouterLink}
            to="/vacations"
            sx={{ mx: 10 }}
          >
            Manage Vacations
          </Button>
        ) : null}
        {permissions?.change_role ? (
          <Button
            variant="outlined"
            startIcon={<GroupsIcon />}
            component={RouterLink}
            to="/team_select"
            sx={{ mx: 10 }}
          >
            Team Select
          </Button>
        ) : null}
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {days
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((day) => {
                  return <AttendanceRow day={day} key={day.date} />;
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={days.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </LocalizationProvider>
    </Paper>
  );
}
