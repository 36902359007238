// https://mui.com/material-ui/react-table/
import * as React from "react";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { api_days, api_season } from "../api";
import moment from "moment";
import ScheduleConfigRow from "../components/ScheduleConfigRow";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const columns = [
  { id: "date", label: "Date", minWidth: null },
  { id: "day", label: "Day of Week", minWidth: null },
  { id: "day_exists", label: "Exists", minWidth: null },
  { id: "start", label: "Start Time", minWidth: null },
  { id: "end", label: "End Time", minWidth: null },
  { id: "display", label: "Display", minWidth: null },
  { id: "closed", label: "Closed", minWidth: null },
  { id: "message", label: "Title", minWidth: null },
];

export default function ScheduleConfiguration() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [days, setDays] = React.useState(undefined);
  const [season, setSeason] = React.useState(undefined);
  const [rows, setRows] = React.useState([]);
  const [allowDestructive, setAllowDestructive] = React.useState(false);

  useEffect(() => {
    api_days()
      .then((res) => {
        setDays(res);
      })
      .then(() => {
        api_season().then((res) => {
          setSeason(res);
        });
      });
  }, []);

  useEffect(() => {
    const make_rows = () => {
      let row_list = [];
      if (days && season?.id) {
        console.log(season);
        let entries_by_date = {};
        days.forEach((el) => {
          entries_by_date[el.date] = el;
        });

        let start_of_season = moment(season.start_date, "YYYY-MM-DD");
        let end_of_season = moment(season.end_date, "YYYY-MM-DD");
        let current = start_of_season;
        if (end_of_season.isBefore(start_of_season)) {
          return row_list;
        }
        while (current.isSameOrBefore(end_of_season, "day")) {
          let day_string = current.format("YYYY-MM-DD");
          if (day_string in entries_by_date) {
            let day_object = entries_by_date[day_string];
            row_list.push({
              date: day_object.date,
              day: current.format("dddd"),
              day_exists: true,
              start: day_object.start,
              end: day_object.end,
              display: day_object.display,
              closed: day_object.closed,
              message: day_object.message,
              url: day_object.url,
            });
          } else {
            row_list.push({
              date: day_string,
              day: current.format("dddd"),
              day_exists: false,
              start:
                current.weekday() < 6 && current.weekday() > 0
                  ? season.default_weekday_start
                  : season.default_weekend_start,
              end:
                current.weekday() < 6 && current.weekday() > 0
                  ? season.default_weekday_end
                  : season.default_weekend_end,
              display: null,
              closed: null,
              message: null,
              url: null,
            });
          }
          current.add(1, "days");
        }
      }
      console.log(row_list);
      return row_list;
    };

    setRows(make_rows());
  }, [days, season]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <ScheduleConfigRow
                      row_data={row}
                      key={row.date}
                      allowDestructive={allowDestructive}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </LocalizationProvider>
      <Box ml={2}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="warning"
                onChange={(event) => setAllowDestructive(event.target.checked)}
              />
            }
            label="Allow destructive edits"
          />
        </FormGroup>
      </Box>
    </Paper>
  );
}
