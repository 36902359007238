import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FlightIcon from "@mui/icons-material/Flight";
import { api_set_attendance } from "../api";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";

export default function AttendanceRow(props) {
  const [dayClosed, setDayClosed] = useState(props.day.closed);
  const [dayStart, setDayStart] = useState(moment(props.day.start, "HH:mm:ss"));
  const [dayEnd, setDayEnd] = useState(moment(props.day.end, "HH:mm:ss"));
  const [dayURL, setDayURL] = useState(props.day.url);
  const [dayAttending, setDayAttending] = useState(props.day.attending);
  const [buttonAttending, setButtonAttending] = useState(undefined);
  const [allowModify, setAllowModify] = useState(false);

  useEffect(() => {
    if (!allowModify || dayAttending === undefined || dayAttending === null) {
      return;
    }
    api_set_attendance(dayAttending, props.day.date)
      .then((res) => {
        setDayURL(res.url);
      })
      .catch(() => {
        toast.error("Something went wrong with saving data.");
      });
  }, [dayAttending]);

  useEffect(() => {
    console.log(dayAttending, props.day);
    if (dayAttending === true) {
      setButtonAttending("Y");
    } else if (dayAttending === false) {
      setButtonAttending("N");
    } else {
      setButtonAttending(undefined);
    }
  }, [dayAttending]);

  useEffect(() => {
    setAllowModify(true);
  }, []);

  const TimeText = () => {
    if (props.day.closed) {
      return <Typography>Closed</Typography>;
    }
    return (
      <Typography>
        {dayStart.format("LT")} - {dayEnd.format("LT")}
      </Typography>
    );
  };

  const AttendingInfo = () => {
    if (props.day.closed) {
      return <Typography>Closed</Typography>;
    } else if (props.day.vacation) {
      return (
        <Button
          variant="outlined"
          startIcon={<FlightIcon />}
          component={RouterLink}
          to="/vacations"
        >
          On Vacation
        </Button>
      );
    }
    return <ToggleAttending />;
  };

  const ToggleAttending = () => {
    // https://mui.com/material-ui/react-toggle-button/#enforce-value-set
    const handle_change = (event, new_state) => {
      if (new_state !== null) {
        setDayAttending(new_state === "Y");
      }
    };

    return (
      <ToggleButtonGroup
        value={buttonAttending}
        onChange={handle_change}
        exclusive
        aria-label="attendance"
      >
        <ToggleButton value="Y" aria-label="yes" color="success">
          <CheckCircleIcon />
        </ToggleButton>
        <ToggleButton value="N" aria-label="no" color="error">
          <CancelIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const mday = moment(props.day.date, "YYYY-MM-DD");

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell key="day">
        <Typography>{mday.format("ddd, MMM Do")}</Typography>
      </TableCell>
      <TableCell key="time">
        <TimeText />
      </TableCell>
      <TableCell key="attending">
        <AttendingInfo />
      </TableCell>
      <TableCell key="message">
        <Typography>{props.day.message}</Typography>
      </TableCell>
    </TableRow>
  );
}
