import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { api_today } from "../api";

export default class ReminderAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  show = () => {
    console.log("showing!");
    this.setState({ open: true });
  };

  hide = (event, callback = null) => {
    this.setState({ open: false });
    if (callback) {
      callback();
    }
  };

  componentDidMount() {
    api_today().then((res) => {
      if (res.show_reminder) {
        this.show();
      }
    });
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.hide}
        aria-labelledby="have-not-filled-out-today"
        aria-describedby="there is no attendance record for you for today"
      >
        <DialogTitle id="alert-dialog-title">
          {"Uh oh! You haven't filled out your attendance!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Would you like to be taken to the attendance page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.hide} variant="outlined">
            No thanks.
          </Button>
          <Button
            onClick={(e) => {
              this.hide(e, () => {
                this.props.navigate("/my_attendance");
              });
            }}
            variant="contained"
          >
            Yes please!
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
