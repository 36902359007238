import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Box, CircularProgress, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { api_me } from "../api";
import Confetti from "react-confetti";

function in_van(name, pilot, copilot, passengers) {
  console.log(name);
  if (!name) {
    return false;
  }
  let n = name.toLowerCase();
  let p = [pilot.toLowerCase(), copilot.toLowerCase()];
  passengers.forEach((pas) => {
    p.push(pas.toLowerCase());
  });
  return p.includes(n);
}

export default function VanPage(props) {
  const [data, setData] = useState(undefined);
  const [whoAmI, setWhoAmI] = useState(undefined);

  useEffect(() => {
    axios
      .get("/api/schedule/vans/")
      .then((result) => {
        const today = moment().startOf("day");
        result.data.forEach((item) => {
          const van_date = moment(item.date).startOf("day");
          if (van_date.isSame(today)) {
            setData(item);
          }
        });
      })
      .catch();
    api_me().then((result) => {
      setWhoAmI(result.username);
    });
  }, []);

  if (!data) {
    return (
      <Box mt={5} align={"center"}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={5} align={"center"}>
      <Typography variant={"h3"}>Seat Assignments</Typography>
      <Typography variant={"caption"}>
        {moment().format("dddd, MMMM Do")}
      </Typography>
      <br />
      <br />
      <Grid container spacing={2} justifyContent="center">
        {data.van_config.map(({ name, pilot, copilot, passengers }, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={name}
            style={{
              backgroundColor: in_van(whoAmI, pilot, copilot, passengers)
                ? "gold"
                : "none",
              borderRadius: "50px",
            }}
          >
            {in_van(whoAmI, pilot, copilot, passengers) &&
            in_van("Grayson", pilot, copilot, passengers) ? (
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={250}
                recycle={false}
              />
            ) : (
              <></>
            )}
            <Typography variant={"h4"}>{name}</Typography>
            <Typography variant={"caption"}>Van {index + 1}</Typography>
            <hr />
            <Typography variant={"h6"}>
              {pilot} & {copilot}
            </Typography>
            {passengers.map((passenger) => (
              <Typography
                key={passenger}
                style={{
                  fontWeight:
                    passenger.toLowerCase() === whoAmI?.toLowerCase()
                      ? "bold"
                      : "normal",
                }}
              >
                {passenger}
              </Typography>
            ))}
            <br />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
