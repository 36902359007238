import axios from "axios";

function get_config() {
  let token = localStorage.getItem("token");
  if (token) {
    return {
      headers: {
        // 'Token': token,
        Authorization: `Token ${token}`,
      },
    };
  }
}

export async function api_today() {
  let result = await axios.get(`/api/schedule/today`, get_config());
  return result.data;
}

export async function api_logout() {
  let result = await axios.post(`/api/auth/token/logout/`, {}, get_config());
  return result.data;
}

export async function api_me() {
  let result = await axios.get(`/api/auth/users/me/`, get_config());
  return result.data;
}

export async function api_permissions() {
  let result = await axios.get(`/api/schedule/permissions`, get_config());
  return result.data;
}

export async function api_days() {
  let result = await axios.get(`/api/schedule/days`, get_config());
  return result.data;
}

export async function api_attendance(date) {
  let result = await axios.get(
    `/api/schedule/attendance`,
    Object.assign({}, get_config(), {
      params: { date: date },
    })
  );
  return result.data;
}

export async function api_all_days() {
  let result = await axios.get(`/api/schedule/days_safe`, get_config());
  return result.data;
}

export async function api_countdown() {
  let result = await axios.get(`/api/schedule/countdown`, get_config());
  return result.data;
}

export async function api_time_spent() {
  let result = await axios.get(`/api/schedule/time_spent`, get_config());
  return result.data;
}

export async function api_season() {
  let result = await axios.get(`/api/schedule/season`, get_config());
  return result.data;
}

export async function api_modify(url, data) {
  let result = await axios.patch(url, data, get_config());
  return result.data;
}

export async function api_delete(url) {
  let result = await axios.delete(url, get_config());
  return result.data;
}

export async function api_create_day(
  date,
  start,
  end,
  closed,
  display,
  message
) {
  let result = await axios.post(
    `/api/schedule/days/`,
    {
      date: date,
      start: start,
      end: end,
      closed: closed,
      display: display,
      message: message,
    },
    get_config()
  );
  return result.data;
}

export async function api_get_role() {
  let result = await axios.get(`/api/schedule/my_role`, get_config());
  return result.data;
}

export async function api_set_role(role) {
  let result = await axios.post(
    `/api/schedule/my_role`,
    {
      team: role,
    },
    get_config()
  );
  return result.data;
}

export async function api_get_attendance() {
  let result = await axios.get(`/api/schedule/my_attendance`, get_config());
  return result.data;
}

export async function api_set_attendance(attending, date) {
  let result = await axios.post(
    `/api/schedule/my_attendance`,
    {
      attending: attending,
      date: date,
    },
    get_config()
  );
  return result.data;
}

export async function api_set_special_attendance(attending, date, username) {
  let result = await axios.post(
    `/api/schedule/special_attendance`,
    {
      action: attending,
      date: date,
      username: username,
    },
    get_config()
  );
  return result.data;
}

export async function api_anyone_see_schedule() {
  let result = await axios.get(
    `/api/schedule/anyone_see_schedule`,
    get_config()
  );
  return result.data;
}

export async function api_get_vacations(username) {
  let result = await axios.get(
    `/api/schedule/vacations`,
    Object.assign({}, get_config(), {
      params: { user__username: username },
    })
  );
  return result.data;
}

export async function api_create_vacation(user, start, end, no_vacations) {
  let data = {
    user: user,
    gone_as_of: start,
    back_as_of: end,
    no_vacations: no_vacations ?? false,
  };
  console.log(data);
  console.log(user);
  let result = await axios.post(`/api/schedule/vacations/`, data, get_config());
  return result.data;
}

export async function api_get_user_url(username) {
  let result = await axios.get(
    `/api/users`,
    Object.assign({}, get_config(), {
      params: { username: username },
    })
  );
  return result.data[0]?.url;
}
