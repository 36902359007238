import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { api_create_day, api_delete, api_modify } from "../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function createData(day_object) {
  console.log(day_object);
  // day_object.display = <Checkbox
  //     defaultChecked={(day_object.display === null || day_object.display)}
  //     disabled={!day_object.exists}
  // />
  // day_object.exists = <Checkbox defaultChecked={day_object.exists}/>
  return day_object;
}

export default function ScheduleConfigRow(props) {
  const [dayExists, setDayExists] = useState(props.row_data.day_exists);
  const [dayDisplay, setDayDisplay] = useState(
    Boolean(!props.row_data.day_exists || props.row_data.display)
  );
  const [dayClosed, setDayClosed] = useState(Boolean(props.row_data.closed));
  const [dayStart, setDayStart] = useState(
    moment(props.row_data.start, "HH:mm:ss")
  );
  const [dayEnd, setDayEnd] = useState(moment(props.row_data.end, "HH:mm:ss"));
  const [dayMessage, setDayMessage] = useState(props.row_data.message);
  const [dayURL, setDayURL] = useState(props.row_data.url);
  const [allowModify, setAllowModify] = useState(false);

  const modify = (data) => {
    if (!dayURL || !allowModify) {
      return;
    }
    console.log(dayURL);
    api_modify(dayURL, data).catch(() => {
      toast.error("Something went wrong with saving data.");
    });
  };

  useEffect(() => {
    if (!allowModify) {
      return;
    }
    if (dayExists) {
      api_create_day(
        props.row_data.date,
        dayStart.format("HH:mm:ss"),
        dayEnd.format("HH:mm:ss"),
        dayClosed,
        dayDisplay,
        dayMessage
      ).then((res) => {
        setDayURL(res.url);
      });
    } else {
      api_delete(dayURL);
    }
  }, [dayExists]);

  useEffect(() => {
    modify({ message: dayMessage });
  }, [dayMessage]);

  useEffect(() => {
    modify({ display: dayDisplay });
  }, [dayDisplay]);

  useEffect(() => {
    modify({ closed: dayClosed });
  }, [dayClosed]);

  useEffect(() => {
    modify({ start: dayStart.format("HH:mm:ss") });
  }, [dayStart]);

  useEffect(() => {
    modify({ end: dayEnd.format("HH:mm:ss") });
  }, [dayEnd]);

  // This has to happen last to avoid sending tons of data back to the server
  useEffect(() => {
    setAllowModify(true);
  }, []);

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell key="date">
        <Typography>{props.row_data.date}</Typography>
      </TableCell>
      <TableCell key="day">
        <Typography>{props.row_data.day}</Typography>
      </TableCell>
      <TableCell key="exists">
        <Checkbox
          checked={dayExists}
          onChange={(event, new_value) => {
            setDayExists(new_value);
          }}
          disabled={!props.allowDestructive && dayExists}
        />
      </TableCell>
      <TableCell key="start">
        <TimePicker
          label="Start"
          value={dayStart}
          onChange={(new_value) => {
            setDayStart(new_value);
          }}
          renderInput={(params) => <TextField {...params} />}
          disabled={!dayExists || dayClosed}
        />
      </TableCell>
      <TableCell key="end">
        <TimePicker
          label="End"
          value={dayEnd}
          onChange={(new_value) => {
            setDayEnd(new_value);
          }}
          renderInput={(params) => <TextField {...params} />}
          disabled={!dayExists || dayClosed}
        />
      </TableCell>
      <TableCell key="display">
        <Checkbox
          checked={dayDisplay}
          disabled={!dayExists}
          onChange={(event, new_value) => {
            setDayDisplay(new_value);
          }}
        />
      </TableCell>
      <TableCell key="closed">
        <Checkbox
          checked={dayClosed}
          disabled={!dayExists}
          onChange={(event, new_value) => {
            setDayClosed(new_value);
          }}
        />
      </TableCell>
      <TableCell key="message">
        <TextField
          defaultValue={dayMessage}
          variant="standard"
          disabled={!dayExists}
          onBlur={(event) => {
            setDayMessage(event.target.value);
          }}
        />
      </TableCell>
    </TableRow>
  );
}
