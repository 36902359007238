import React, { useEffect, useState } from "react";
import moment from "moment";
import { api_countdown, api_time_spent } from "../api";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Container from "@mui/material/Container";
import "moment-duration-format";

const Countdown = () => {
  const [remainingTime, setRemainingTime] = useState(moment.duration());
  const [timeSpent, setTimeSpent] = useState(moment.duration());
  const [timeSpentBase, setTimeSpentBase] = useState(moment.duration());
  const [meetingsLeft, setMeetingsLeft] = useState(0);

  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);

  useEffect(() => {
    api_time_spent().then((seconds) => {
      setTimeSpentBase(moment.duration(seconds, "seconds"));
    });
  }, []);

  useEffect(() => {
    let interval = null;

    api_countdown().then((schedule) => {
      const calculateRemainingTime = () => {
        let remaining = moment.duration();
        let spent = moment.duration();
        let meetings_left = 0;
        const now = moment(moment.now());

        schedule.forEach((item) => {
          if (!item.closed) {
            const startTime = moment(`${item.date} ${item.start}`);
            const endTime = moment(`${item.date} ${item.end}`);
            if (now.isAfter(startTime) && now.isBefore(endTime)) {
              remaining.add(endTime.diff(now), "milliseconds");
              spent.add(now.diff(startTime, "milliseconds"));
              meetings_left += endTime.diff(now) / endTime.diff(startTime);
            } else if (now.isBefore(startTime)) {
              remaining.add(endTime.diff(startTime), "milliseconds");
              meetings_left += 1;
            }
          }
        });

        setRemainingTime(remaining);
        setTimeSpent(spent);
        setMeetingsLeft(meetings_left);
      };

      calculateRemainingTime();
      interval = setInterval(calculateRemainingTime, 1000);
    });

    return () => clearInterval(interval); // cleanup interval on component unmount
  }, []);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Box align={"center"}>
        <Typography variant={"h1"} color={"red"} fontWeight="bold">
          {remainingTime.format("HH:mm:ss", { trim: false })}
        </Typography>
        <Typography variant={"h4"} color={"red"}>
          UNTIL COMPETITION
        </Typography>
        <Grid container xs={4} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <Typography variant={"subtitle2"} color={"red"}>
              {(
                ((timeSpent.asSeconds() + timeSpentBase.asSeconds()) /
                  (timeSpent.asSeconds() +
                    timeSpentBase.asSeconds() +
                    remainingTime.asSeconds())) *
                100
              ).toFixed(2)}
              %
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={"subtitle2"} color={"red"}>
              {meetingsLeft.toFixed(2)} Meetings Left
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Countdown;
