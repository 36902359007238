import { useRouteError } from "react-router-dom";
import { Typography } from "@mui/material";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Typography variant="h2" align="center">
        That's not good...
      </Typography>
      <Typography variant="h6" align="center">
        Error {error.status}: {error.statusText || error.message}
      </Typography>
    </div>
  );
}
