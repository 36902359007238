import React, { useEffect, useState } from "react";
import { api_logout, api_me, api_permissions } from "../api";
import { toast, ToastContainer } from "react-toastify";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

const drawerWidth = 240;

export default function MenuBar(props) {
  const [isAuth, setIsAuth] = useState(false);
  const [username, setUsername] = useState(undefined);
  const [permissions, setPermissions] = useState();
  const navigate = useNavigate();
  const [onPressToday, setOnPressToday] = useState(true);

  const on_logout = () => {
    api_logout()
      .then(() => {
        localStorage.clear();
        setIsAuth(false);
        setPermissions(null);
        toast.success("You are logged out!");
        on_press_today();
        navigate("/");
      })
      .catch(() => {
        localStorage.clear();
        setIsAuth(false);
        setPermissions(null);
        navigate("/sign_in");
      });
  };

  function on_press_today() {
    setOnPressToday(!onPressToday);
  }

  const navItems = [
    {
      text: "Today",
      condition: isAuth,
      link: "/",
      on_click: on_press_today,
    },
    {
      text: "Attendance",
      link: "/my_attendance",
      condition: isAuth,
      on_click: null,
    },
    {
      text: "Configure",
      link: "/configure",
      condition: permissions?.can_configure,
      on_click: null,
    },
    {
      text: "Sign In",
      link: "/sign_in",
      condition: !isAuth,
      on_click: null,
    },
    {
      text: `Sign Out (${username})`,
      link: null,
      condition: isAuth,
      on_click: on_logout,
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      api_me()
        .then((result) => {
          setIsAuth(true);
          setUsername(result.username);
          api_permissions()
            .then((result) => {
              setPermissions(result);
            })
            .catch(on_logout);
        })
        .catch(on_logout);
    }
  }, []);

  // https://mui.com/material-ui/react-app-bar/
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const create_button = (item) => {
    if (!item.condition) {
      return;
    }

    if (item.link) {
      return (
        <Button
          key={item.text}
          sx={{ color: "#fff" }}
          component={RouterLink}
          to={item.link}
          variant="inherit"
          onClick={item.on_click}
        >
          {item.text}
        </Button>
      );
    }

    if (item.on_click) {
      return (
        <Button
          key={item.text}
          sx={{ color: "#fff" }}
          onClick={item.on_click}
          variant="inherit"
        >
          {item.text}
        </Button>
      );
    }
  };

  const create_list_item = (item) => {
    if (!item.condition) {
      return;
    }

    if (item.link) {
      return (
        <ListItem key={item.text} disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            component={RouterLink}
            to={item.link}
            onClick={item.on_click}
          >
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      );
    }

    if (item.on_click) {
      return (
        <ListItem key={item.text} disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={item.on_click}>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      );
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        DRS Schedule
      </Typography>
      <Divider />
      <List>{navItems.map((item) => create_list_item(item))}</List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" position="static">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              DRS Schedule
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => create_button(item))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <br />
      <Outlet context={[isAuth, onPressToday, permissions]} />
    </>
  );
}
