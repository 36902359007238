import * as React from "react";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import ListOfDayAttendance from "../components/ListOfDayAttendance";
import Typography from "@mui/material/Typography";
import {
  api_all_days,
  api_anyone_see_schedule,
  api_attendance,
  api_today,
} from "../api";
import moment from "moment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReminderAlert from "../components/ReminderAlert";

export default function SingleDayView() {
  const navigate = useNavigate();

  const [day, setDay] = useState(undefined);
  const [today, setToday] = useState(undefined);
  const [hideUndefined, setHideUndefined] = useState(true);
  const [isAuth, onPressToday, permissions] = useOutletContext();

  const [allDays, setAllDays] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [todayIndex, setTodayIndex] = useState(undefined);
  const [onIndex, setOnIndex] = useState(false);
  const [hasToday, setHasToday] = useState(false);

  const [allowAnyoneSeeAllDays, setAllowAnyoneSeeAllDays] = useState(false);

  useEffect(() => {
    api_today().then((res) => {
      setDay(res ? res : null);
      setToday(res ? res : null);
    });
    api_anyone_see_schedule().then((res) => {
      setAllowAnyoneSeeAllDays(res);
    });
  }, []);

  useEffect(() => {
    setSelectedIndex(todayIndex);
    if (!hasToday) {
      setOnIndex(false);
      setDay(today);
    }
  }, [onPressToday]);

  useEffect(() => {
    setHideUndefined(!day || day.attendance?.unknown?.length === 0);
  }, [day]);

  useEffect(() => {
    if (!isAuth && !allowAnyoneSeeAllDays) {
      return;
    }
    api_all_days().then((res) => {
      setAllDays(res);
    });
  }, [isAuth, allowAnyoneSeeAllDays]);

  useEffect(() => {
    if (!allDays.length) {
      return;
    }
    let now = moment.now();
    let i = 0;

    let is_same_day = false;

    while (i < allDays.length) {
      let day = allDays[i];
      let date = moment(day.date, "YYYY-MM-DD");
      if (date.isSame(now, "day")) {
        is_same_day = true;
        break;
      } else if (date.isAfter(now, "day")) {
        break;
      }
      i += 1;
    }
    setOnIndex(is_same_day);
    setHasToday(is_same_day);
    setSelectedIndex(i);
    setTodayIndex(i);
  }, [allDays]);

  useEffect(() => {
    if (!onIndex || !allDays) {
      return;
    }
    setDay(undefined);
    api_attendance(allDays[selectedIndex].date)
      .then((res) => {
        setDay(res);
      })
      .catch(() => {
        if (isAuth) {
          toast.error("Something went wrong");
        }
        setDay(today);
      });
  }, [selectedIndex, onIndex]);

  const on_next = () => {
    change_day(1);
  };

  const on_back = () => {
    change_day(-1);
  };

  const change_day = (amount) => {
    if (!onIndex && amount > 0) {
      setOnIndex(true);
    } else {
      setSelectedIndex(selectedIndex + amount);
    }

    if (!onIndex) {
      setOnIndex(true);
    }
  };

  const meetings_remaining = () => {
    if (!allDays) {
      return "";
    }
    let days_remaining = 0;
    for (let i = selectedIndex; i < allDays.length - 1; i++) {
      if (!allDays[i].closed) {
        days_remaining += 1;
      }
    }

    return days_remaining !== 0 ? days_remaining : "";
  };

  const AttendanceList = () => {
    if (day === undefined) {
      return (
        <Box align="center">
          <CircularProgress />
        </Box>
      );
    } else if (!day) {
      return (
        <Typography variant="h4" align="center">
          No Meeting Today
        </Typography>
      );
    } else if (day.day.closed) {
      return (
        <Typography variant="h4" align="center">
          Closed
        </Typography>
      );
    }
    return (
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4}>
          <ListOfDayAttendance
            attendance={day.attendance?.software}
            permissions={permissions}
            date={day.day.date}
            start={day.day.start}
            end={day.day.end}
            title="Software"
          />
        </Grid>
        <Grid item xs={4}>
          <ListOfDayAttendance
            attendance={day.attendance?.hardware}
            permissions={permissions}
            date={day.day.date}
            start={day.day.start}
            end={day.day.end}
            title="Hardware"
          />
        </Grid>
        <Grid item xs={4}>
          <ListOfDayAttendance
            attendance={day.attendance?.mentor}
            permissions={permissions}
            date={day.day.date}
            start={day.day.start}
            end={day.day.end}
            title="Mentor"
          />
        </Grid>
        <Grid item xs={12} hidden={hideUndefined}>
          <ListOfDayAttendance
            attendance={day.attendance?.unknown}
            permissions={permissions}
            date={day.day.date}
            start={day.day.start}
            end={day.day.end}
            title="Undecided"
            align="center"
          />
        </Grid>
      </Grid>
    );
  };

  const ControlBar = () => {
    if (!isAuth && !allowAnyoneSeeAllDays) {
      return <br />;
    }
    return (
      <Box
        align="center"
        style={{
          color:
            onIndex && !allDays[selectedIndex].closed ? "black" : "lightgray",
        }}
      >
        <IconButton
          color="secondary"
          aria-label="previous day"
          onClick={on_back}
          disabled={selectedIndex === undefined || selectedIndex <= 0}
        >
          <NavigateBeforeIcon />
        </IconButton>
        {meetings_remaining()}
        <IconButton
          color="secondary"
          aria-label="next day"
          onClick={on_next}
          disabled={
            selectedIndex === undefined || selectedIndex >= allDays.length - 1
          }
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <ReminderAlert navigate={navigate} />
      <Typography variant="h2" align="center">
        {day?.day?.message ? day?.day?.message : "Attendance"}
      </Typography>
      <Typography variant="h6" align="center">
        {day
          ? moment(day?.day?.date, "YYYY-MM-DD").format("dddd, MMMM Do")
          : day === undefined
          ? "..."
          : moment().format("dddd, MMMM Do")}
      </Typography>
      <ControlBar />
      <hr width="90%" />
      <br />
      <AttendanceList />
    </React.Fragment>
  );
}
