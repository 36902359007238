import Confetti from "react-confetti";
import Button from "@mui/material/Button";
import { useState } from "react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import Typography from "@mui/material/Typography";
import { api_set_role } from "../api";
import { toast } from "react-toastify";

function MyModel({ open, handleClose, children, color }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: color ?? "gold",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open ?? false}
      onClose={handleClose ?? (() => {})}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open ?? false}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

export default function TeamSelect(props) {
  const CLICKS_REQUIRED = 11;

  const [team, setTeam] = useState(undefined);

  const [swOpen, setSwOpen] = useState(false);
  const handleSWClose = () => setSwOpen(false);

  const [hwOpen, setHwOpen] = useState(false);
  const handleHWClose = () => setHwOpen(false);

  const [confirmClicks, setConfirmClicks] = useState(0);

  const [altHWContents, setAltHWContents] = useState(false);

  const getConfirmText = () => {
    if (confirmClicks == 0) {
      return "It is not too late to change your mind... there is another option.";
    } else if (confirmClicks < CLICKS_REQUIRED - 1) {
      return `Oops, I think you misclicked. Click it ${
        CLICKS_REQUIRED - confirmClicks
      } more times if you really meant it.`;
    } else {
      return "Oops, I think you misclicked. Let me fix the button layout for you.";
    }
  };

  const handle_api = (r) => {
    if (r.status == "warning") {
      toast.warn(r.message);
    } else if (r.status == "error") {
      toast.error(r.message);
    } else {
      console.log(r);
      toast.success("Done");
    }
  };

  const onSoftware = () => {
    setTeam("SOFTWARE");
    setSwOpen(true);
    api_set_role("SW")
      .then(handle_api)
      .catch((e) => {
        toast.error("Something went wrong, try again later.");
      });
  };

  const onHardware = () => {
    setTeam("HARDWARE");
    setHwOpen(true);
    setConfirmClicks(0);
    setAltHWContents(false);
  };

  const AltHardwareModelContents = () => {
    if (confirmClicks == CLICKS_REQUIRED) {
      return (
        <>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Well, ok then.
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            You have joined the Hardware Team.
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography id="transition-modal-title" variant="h6" component="h2">
          Canceled!
        </Typography>
        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          You did not join the Hardware Team.
        </Typography>
        <br />
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            setHwOpen(false);
          }}
          sx={{ mx: 1 }}
          style={{ minWidth: 150 }}
        >
          Ok
        </Button>
      </>
    );
  };

  const NormalHardwareModelContents = () => {
    return (
      <>
        <Typography id="transition-modal-title" variant="h6" component="h2">
          Are you sure about this?
        </Typography>
        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          {getConfirmText()}
        </Typography>
        <br />
        {confirmClicks == CLICKS_REQUIRED - 1 ? (
          <>
            <Button
              color="warning"
              variant="contained"
              style={{ minWidth: 150 }}
              sx={{ mx: 1 }}
              onClick={() => {
                setConfirmClicks((prev) => prev + 1);
                setAltHWContents(true);
                api_set_role("HW")
                  .then(handle_api)
                  .catch((e) => {
                    toast.error("Something went wrong, try again later.");
                  });
              }}
            >
              I am sure.
            </Button>
            <Button
              color="success"
              variant="contained"
              onClick={() => {
                setAltHWContents(true);
              }}
              sx={{ mx: 1 }}
              style={{ minWidth: 150 }}
            >
              CANCEL!
            </Button>
          </>
        ) : (
          <>
            <Button
              color="success"
              variant="contained"
              onClick={() => {
                setHwOpen(false);
              }}
              sx={{ mx: 1 }}
              style={{ minWidth: 150 }}
            >
              CANCEL!
            </Button>
            <Button
              color="warning"
              variant="contained"
              style={{ minWidth: 150 }}
              sx={{ mx: 1 }}
              onClick={() => {
                setConfirmClicks((prev) => prev + 1);
              }}
            >
              I am sure.
            </Button>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {team == "SOFTWARE" ? (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={250}
          recycle={true}
        />
      ) : null}
      <MyModel open={swOpen} handleClose={handleSWClose}>
        <Typography id="transition-modal-title" variant="h6" component="h2">
          You did it!
        </Typography>
        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          Congratulations on joining the Software Team!
        </Typography>
      </MyModel>
      <MyModel open={hwOpen} handleClose={handleHWClose} color={"#ed5a5a"}>
        {altHWContents ? (
          <AltHardwareModelContents />
        ) : (
          <NormalHardwareModelContents />
        )}
      </MyModel>
      <div align={"center"}>
        <Typography variant={"h3"} sx={{ my: 5 }}>
          Team Selection
        </Typography>
      </div>
      <div align={"center"}>
        <Button variant="contained" onClick={onSoftware} sx={{ mx: 5 }}>
          Join Software!
        </Button>
        <Button variant="outlined" onClick={onHardware} sx={{ mx: 5 }}>
          Join Hardware
        </Button>
      </div>
    </>
  );
}
