// Modified from MaterialUI template

import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Copyright(props) {
  return null;
  // return (
  //     <Typography variant="body2" color="text.secondary" align="center" {...props}>
  //         {'Copyright © '}
  //         <Link color="inherit" href="https://mui.com/">
  //             Dead Robot Society
  //         </Link>{' '}
  //         {new Date().getFullYear()}
  //         {'.'}
  //     </Typography>
  // );
}

const theme = createTheme();

export default function SignIn(props) {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { redirect } = state ?? {}; // Read values passed on state
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const user = {
      username: data.get("username"),
      password: data.get("password"),
    };

    fetch("/api/auth/token/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => {
        return Promise.all([res.json(), res.ok]);
      })
      .then((data_list) => {
        let data = data_list[0];
        let status = data_list[1];

        if (!status) {
          console.log(data);
          let message = "Error logging in!";
          if ("username" in data) {
            message = `Username: ${data.username[0]}`;
          } else if ("password" in data) {
            message = `Password: ${data.password[0]}`;
          } else if ("non_field_errors" in data) {
            message = data.non_field_errors[0];
          }
          toast.error(message);
          localStorage.clear();
        }

        if (data.auth_token) {
          localStorage.clear();
          localStorage.setItem("token", data.auth_token);
          if (redirect) {
            navigate(redirect);
          } else {
            navigate("/");
          }
        } else {
          localStorage.clear();
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              // required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              // required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/*<FormControlLabel*/}
            {/*    control={<Checkbox value="remember" color="primary"/>}*/}
            {/*    label="Remember me"*/}
            {/*/>*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Button
              component={RouterLink}
              to="/"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              Back
            </Button>
          </Box>
        </Box>
        <ToastContainer />
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
