import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import SingleDayView from "./pages/SingleDayView";
import ErrorPage from "./pages/ErrorPage";
import SignIn from "./pages/SignIn";
import MenuBar from "./components/MenuBar";
import ScheduleConfiguration from "./pages/ScheduleConfiguration";
import MyAttendance from "./pages/MyAttendance";
import VacationView from "./pages/VacationView";
import VanPage from "./pages/VanPage";
import TeamSelect from "./pages/TeamSelect";
import Countdown from "./pages/Doomsday";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MenuBar />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <SingleDayView />,
      },
      {
        path: "/configure",
        element: <ScheduleConfiguration />,
      },
      {
        path: "/my_attendance",
        element: <MyAttendance />,
      },
      {
        path: "/team_select",
        element: <TeamSelect />,
      },
    ],
  },
  {
    path: "sign_in",
    element: <SignIn />,
  },
  {
    path: "/vacations",
    element: <VacationView />,
  },
  {
    path: "/vans",
    element: <VanPage />,
  },
  {
    path: "/doomsday",
    element: <Countdown />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <React.Fragment>
      <CssBaseline />
      <RouterProvider router={router} />
    </React.Fragment>
  </React.StrictMode>,
  document.getElementById("root")
);
